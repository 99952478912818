import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import { Layout, PageContentWrapper, SEO } from '@components';
import { Box } from '@core';
import { DOMAIN, META_IMAGE } from '@shared/constants/constants';
import { BLOG_CATEGORIES } from '@shared/constants/blog';
import { blogTheme } from '@theme';

import { BlogListNavigation, BlogPostsList, Categories, Header } from './components';

const Blog = (props) => {
  const { data } = props;
  const { currentPage, numberOfPages, categoryLink } = props.pageContext;
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;
  const metaData = BLOG_CATEGORIES.find((category) => category.link === categoryLink).metaData;

  return (
    <ThemeProvider theme={blogTheme}>
      <Helmet>
        <link
          rel="canonical"
          href={`${DOMAIN.mainUrl}/blog${categoryLink !== '/' ? categoryLink : ''}`}
          data-react-helmet="true"
        />
      </Helmet>
      <Layout location={props.location}>
        <SEO title={metaData.title} description={metaData.description} image={META_IMAGE} />
        <Box backgroundColor="white" px={{ lg: 16 }}>
          <PageContentWrapper py={{ _: 30, lg: '2.65rem' }} px={{ _: 20, lg: 0 }}>
            <Header
              title={siteTitle + ' blog'}
              description="Your daily reads on everything digital"
            />
            <Categories categories={BLOG_CATEGORIES} selectedCategory={categoryLink} />
            <BlogPostsList posts={posts} currentPage={currentPage} />
            <BlogListNavigation
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              categoryLink={categoryLink}
            />
          </PageContentWrapper>
        </Box>
      </Layout>
    </ThemeProvider>
  );
};

export default Blog;

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $categoriesToIgnore: [Int]!, $language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "navigation"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { author: { ne: null }, category: { nin: $categoriesToIgnore } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
            featuredimage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            category
          }
          timeToRead
        }
      }
    }
  }
`;
